@use "./reset.scss";

.background {
  position: absolute;
  inset: 0;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  h1 {
    color: #fff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: clamp(1.5rem, 1.1359rem + 1.5534vw, 3rem);
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    opacity: 0.85;

    &::before {
      content: "Pixels in the North";
      position: absolute;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      color: #fff;
      transform: scale(1.1);
      white-space: nowrap;
      filter: blur(6px);
      mix-blend-mode: soft;
      border: 1px solid green;
    }

    &::after {
      content: "Pixels in the North";
      position: absolute;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      color: #fff;
      transform: scale(1.2);
      white-space: nowrap;
      filter: blur(16px);
      mix-blend-mode: soft-light;
      border: 1px solid green;
      opacity: 0.5;
    }
  }
}
