*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.background {
  position: absolute;
  inset: 0;
}

.background img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.background h1 {
  color: #fff;
  width: 100%;
  text-align: center;
  opacity: .85;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: clamp(1.5rem, 1.1359rem + 1.5534vw, 3rem);
  position: absolute;
  top: 50%;
}

.background h1:before {
  content: "Pixels in the North";
  z-index: -1;
  color: #fff;
  white-space: nowrap;
  filter: blur(6px);
  mix-blend-mode: soft;
  border: 1px solid green;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.background h1:after {
  content: "Pixels in the North";
  z-index: -1;
  color: #fff;
  white-space: nowrap;
  filter: blur(16px);
  mix-blend-mode: soft-light;
  opacity: .5;
  border: 1px solid green;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/*# sourceMappingURL=index.066225d8.css.map */
